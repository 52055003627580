"use strict";



// SITE CLASS



var pg = {};

pg.static = {

	contactEmailSent: function() {
		pg.loader.fullscreen.end();
		//alert('Cool');
	},

	registrationSuccess: function() {
		$('.registration-errors').hide();
		$('.registration-success').show();
		pg.loader.fullscreen.end();
	},

	disableImageDownload: function() {
		$('img').bind('contextmenu', function(e) {
			return false;
		}); 
	}

}

pg.menu = function() {

	var _perfectSidebarScroll = function () {
		if ($('.st-menu').css('position') == 'fixed') {
			$('.st-menu').css({'min-height': 0});
			$('.st-menu').perfectScrollbar();
		} else {
			$('.st-menu').perfectScrollbar('destroy');
		}
	}

	var _sidebarMinHeight = function () {
		var menuHeaderHeight = $('.st-menu .menu-header').outerHeight(true),
			menuHeight = $('.st-menu .metismenu').outerHeight(true),
			menuFooterHeight = $('.st-menu .menu-footer').outerHeight(true) + (parseInt($('.st-menu .menu-footer').css('bottom')) * 2),
			minHeight = menuHeaderHeight + menuHeight + menuFooterHeight;

		if ($('.st-menu').css('position') != 'fixed') {
			$('.st-menu').css({'min-height': minHeight + 'px'});
		}
	}

	/* Init sidebar scrolling */
	setTimeout(function(){
		_perfectSidebarScroll();
		_sidebarMinHeight();
	},100)

	$(window).resize(function(){
		_perfectSidebarScroll();
		_sidebarMinHeight();
	})

}

pg.works = {}

pg.loader = {}

pg.loader.fullscreen = {
	start: function(options) {

		var defaults = {
			theme: "sk-rect",
			message: 'Loading...'
		}

		var settings = $.extend(true, defaults, options);

		HoldOn.open(settings);
	},
	end: function() {
		setTimeout(
			function(){
				HoldOn.close();
			},
			200
		);
	}
}

pg.works = function() {
	$('section.works .open-video-popup').unbind('click').bind('click', function(e){
		var el = $(this),
			id = el.data('id'),
			type = el.data('type');

		e.preventDefault();

		pg.loader.fullscreen.start();

		$.ajax({
			method: "POST",
			url: "/ajax/video",
			dataType : 'JSON',
			data: {
				_token: _token,
				id: id,
				type: type
			}
		}).done(function( response ) {
			pg.loader.fullscreen.end();
			if (response.status == 'success') {
				var content = response.data,
					htmlContainer = $('#work-popup');

				switch (content.type) {
					case 'video':
						htmlContainer.find('.image').hide();

						var video = htmlContainer.find('.video');
						var description = htmlContainer.find('.description');

						video.find('.iframe-video').attr('src', content.link);

						description.find('p.title').html(content.description.title);
						description.find('p.data').remove();

						$.each(content.description.data, function(key, value){
							$( '<p class="data"><span class="title">' + key + ':</span> ' + value + '</p>' ).appendTo( description );
						}); 

						video.show();

						break;
					case 'photo':
						htmlContainer.find('.video').hide();

						var image = htmlContainer.find('.image');

						image.show();
						break;
				}

				setTimeout(
					function(){
						$.fancybox({ 
							content: htmlContainer,
							autoHeight: true,
							autoWidth: true,
							autoResize: true,
							maxWidth: '100%',
							height: 'auto',
							fitToView: false,
						});
					},
					200
				);
				
			} else {
				D('!!!');
				D(response);
			}

			if (response.error == 0) {
				//$('.sucess-status-update').html(msg.message);
				//alert(msg.message);
			} else {
				//alert(msg.message);
				//$('.error-favourite-message').html(msg.message);
			}
		});
	});

}

pg.works.mixitup = function() {
	$('section.works').mixItUp({
		callbacks: {
			onMixEnd: function() {
				$('body').trigger('scroll');
			}
		},
		load: {
			filter: '.only-1'
		}
	});
}

pg.faces = {}

pg.faces = function() {

	$('.faces .item:visible a[rel="our-faces"]').fancybox({
		openEffect  : 'elastic'
	});
}

pg.faces.mixitup = function() {
	$('section.faces').mixItUp({
		callbacks: {
			onMixEnd: function() {
				$('body').trigger('scroll');
			}
		},
	});

	$('section.faces').on('mixEnd', function(e, state){
		$(document).unbind('click.fb-start');
		pg.faces();
	});

}

pg.registration = function() {
	$('#registration').on('err.form.fv', function() {
		$('.registration-success').hide();
		$('.registration-errors').show();
	});
}

pg.aboutUs = function() {
	$('.office-images .item a[rel="office-images"]').fancybox({
		openEffect  : 'elastic'
	});
}

pg.misc = {}



// INIT CLASS



var init = {
	common: function() {
		pg.menu();
		pg.static.disableImageDownload();

		// Lazy Load moka 
		$("img.lazy").lazyload({
			effect : "fadeIn"
		});

		$('#st-container').scroll(function(){
			$('body').trigger('scroll')
		})
	},

	page: {
		index: function(dataProvider) {
			init.common();
			pg.works();
			pg.faces();
		},
		aboutUs: function(dataProvider) {
			init.common();
			pg.aboutUs();
		},
		news: function(dataProvider) {
			init.common();
		},
		newsArticle: function(dataProvider) {
			init.common();
		},
		faces: function(dataProvider) {
			init.common();
			pg.faces.mixitup();
		},
		works: function(dataProvider) {
			init.common();
			pg.works();
			pg.works.mixitup();
		},
		clients: function(dataProvider) {
			init.common();
		},
		contact: function(dataProvider) {
			init.common();
		},
		registration: function(dataProvider) {
			init.common();
			pg.registration();
		},
	}

}



// FUNCTIONS



function D(value, mode) {
	if (mode === undefined) {
		console.log(value);
	} else {
		switch (mode) {
			case 'i' :
				console.info(value);
				break;ar
			case 'd' :
				console.debug(value);
				break;
			case 'e' :
				console.error(value);
				break;
			default :
				console.error('Undefined debug mode: ' + value);
				break;
		}
	}
}

function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function removeCookie(name) {
    createCookie(name,"",-1);
}


(function(window){'use strict';
    
    function HoldOnAction(){
            if("undefined"==typeof jQuery){
                throw new Error("HoldOn.js requires jQuery");
            }
            
            var HoldOn = {};
            
            HoldOn.open = function(properties){
                $('#holdon-overlay').remove();//RemoveIfCalledBefore
                var theme = "sk-rect";
                var content = "";
                var message = "";
                
                if(properties){
                    if(properties.hasOwnProperty("theme")){//Choose theme if given
                        theme = properties.theme;
                    }
                    
                    if(properties.hasOwnProperty("message")){//Choose theme if given
                        message = properties.message;
                    }
                }
                
                switch(theme){
                    case "custom":
                        content = '<div style="text-align: center;">' + properties.content + "</div>";
                    break;
                    case "sk-dot":
                        content = '<div class="sk-dot"> <div class="sk-dot1"></div> <div class="sk-dot2"></div> </div>';
                    break;
                    case "sk-rect":
                        content = '<div class="sk-rect"> <div class="rect1"></div> <div class="rect2"></div> <div class="rect3"></div> <div class="rect4"></div> <div class="rect5"></div> </div>';
                    break;
                    case "sk-cube":
                        content = '<div class="sk-cube"> <div class="sk-cube1"></div> <div class="sk-cube2"></div> </div>';
                    break;
                    case "sk-bounce":
                        content = '<div class="sk-bounce"> <div class="bounce1"></div> <div class="bounce2"></div> <div class="bounce3"></div> </div>';
                    break;
                    case "sk-circle":
                        content = '<div class="sk-circle"> <div class="sk-circle1 sk-child"></div> <div class="sk-circle2 sk-child"></div> <div class="sk-circle3 sk-child"></div> <div class="sk-circle4 sk-child"></div> <div class="sk-circle5 sk-child"></div> <div class="sk-circle6 sk-child"></div> <div class="sk-circle7 sk-child"></div> <div class="sk-circle8 sk-child"></div> <div class="sk-circle9 sk-child"></div> <div class="sk-circle10 sk-child"></div> <div class="sk-circle11 sk-child"></div> <div class="sk-circle12 sk-child"></div> </div>';
                    break;
                    case "sk-cube-grid":
                        content = '<div class="sk-cube-grid"> <div class="sk-cube-child sk-cube-grid1"></div> <div class="sk-cube-child sk-cube-grid2"></div> <div class="sk-cube-child sk-cube-grid3"></div> <div class="sk-cube-child sk-cube-grid4"></div> <div class="sk-cube-child sk-cube-grid5"></div> <div class="sk-cube-child sk-cube-grid6"></div> <div class="sk-cube-child sk-cube-grid7"></div> <div class="sk-cube-child sk-cube-grid8"></div> <div class="sk-cube-child sk-cube-grid9"></div> </div>';
                    break;
                    case "sk-folding-cube":
                        content = '<div class="sk-folding-cube"> <div class="sk-cubechild1 sk-cube-parent"></div> <div class="sk-cubechild2 sk-cube-parent"></div> <div class="sk-cubechild4 sk-cube-parent"></div> <div class="sk-cubechild3 sk-cube-parent"></div> </div>';
                    break;
                    case "sk-fading-circle":
                        content = '<div class="sk-fading-circle"> <div class="sk-fading-circle1 sk-circle-child"></div> <div class="sk-fading-circle2 sk-circle-child"></div> <div class="sk-fading-circle3 sk-circle-child"></div> <div class="sk-fading-circle4 sk-circle-child"></div> <div class="sk-fading-circle5 sk-circle-child"></div> <div class="sk-fading-circle6 sk-circle-child"></div> <div class="sk-fading-circle7 sk-circle-child"></div> <div class="sk-fading-circle8 sk-circle-child"></div> <div class="sk-fading-circle9 sk-circle-child"></div> <div class="sk-fading-circle10 sk-circle-child"></div> <div class="sk-fading-circle11 sk-circle-child"></div> <div class="sk-fading-circle12 sk-circle-child"></div> </div>';
                    break;
                    default:
                        content = '<div class="sk-rect"> <div class="rect1"></div> <div class="rect2"></div> <div class="rect3"></div> <div class="rect4"></div> <div class="rect5"></div> </div>';
                        console.warn(theme + " doesn't exist for HoldOn.js");
                    break;
                }
                
                var Holder    = '<div id="holdon-overlay" style="display: none;">\n\
                                    <div id="holdon-content-container">\n\
                                        <div id="holdon-content">'+content+'</div>\n\
                                        <div id="holdon-message">'+message+'</div>\n\
                                    </div>\n\
                                </div>';
                
                $(Holder).appendTo('body').fadeIn(300);
                
                if(properties){
                    if(properties.backgroundColor){
                        $("#holdon-overlay").css("backgroundColor",properties.backgroundColor);
                    }
                    
                    if(properties.backgroundColor){
                        $("#holdon-message").css("color",properties.textColor);
                    }
                }
            };
            
            HoldOn.close = function(){
                $('#holdon-overlay').fadeOut(300, function(){
                    $(this).remove();
                });
            };
            
        return HoldOn;
    }
    
    if(typeof(HoldOn) === 'undefined'){
        window.HoldOn = HoldOnAction();
    }
    
})(window);